<template>
  <div class="threads">
    <ul class="threads__list">
      <Thread v-for="message in threads" :key="message.id" :message="message" />
    </ul>
  </div>
</template>

<script>
import Thread from "@/modules/Messenger/components/ChatModal/Threads/Thread";
import { mapState } from "vuex";
export default {
  name: "ThreadsModal",
  mixins: [],
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  components: { Thread },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["threads"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.threads {
  width: 100%;
  padding: 12px;
  max-height: calc(100% - 58px);
  overflow-y: auto;
  &__list {
    all: unset;
    display: flex;
    flex-flow: column;
    width: 100%;
  }
}
</style>
