<template>
  <li class="thread" @click="openCurrentThread" :id="'thread_' + message.id">
    <Avatar :user="message.userInfo" />
    <div class="thread__content">
      <h3 class="thread__title">
        <span class="thread__username"> {{ message.userInfo.full_name }} </span>
        <span class="thread__message">
          <i class="icon-comment-empty thread__icon"></i>
          <span
            class="thread__new-message"
            v-if="message.thread && message.thread.count_unread_messages"
          ></span>
        </span>
        <span class="thread__icon-wrap">
          <i class="icon-group thread__icon"></i>
          <span class="thread__count" v-if="message.thread">{{
            message.thread.count_members
          }}</span>
        </span>
        <span class="thread__time">{{ setTime }}</span>
      </h3>
      <p class="thread__message-text">
        {{ setTextMessage }}
      </p>
    </div>
  </li>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import elementInViewport from "@/modules/Messenger/mixins/elementInViewport.mixin";
export default {
  name: "Thread",
  mixins: [elementInViewport],
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  components: { Avatar },
  data() {
    return {};
  },
  mounted() {
    this.setDefault(".threads", ".threads__list");
    this.el = document.querySelector(`#thread_${this.message.id}`);
    this.observer.observe(this.el);
  },
  methods: {
    ...mapActions("MessengerModule", ["threadOpen", "closeThread", "getMessageWithScroll"]),
    openCurrentThread() {
      this.threadOpen(this.message);
    },
    handleView() {
      const length = this.threads.length;
      const index = this.threads.findIndex((item) => item.id === this.message.id);
      if (length - 5 === index && length >= 50 && this.isVisible) {
        this.getMessageWithScroll(this.message.id);
      }
    }
  },
  computed: {
    ...mapState("MessengerModule", ["threadMode", "threads"]),
    setTextMessage() {
      return this.message.text ? this.message.text : "file";
    },
    setTime() {
      if (this.message) {
        moment.locale("ru");
        if (moment(this.message.updated_at).format("L") === moment().format("L")) {
          return moment(this.message.updated_at).format("LT");
        } else {
          return moment(this.message.updated_at).format("L");
        }
      } else {
        return "";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.thread {
  display: flex;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  @media (hover: hover) {
    &:hover {
      background-color: $message;
      .thread__new-message {
        border-color: $message;
      }
    }
  }
  /deep/ {
    .avatar__img {
      width: 40px;
      height: 40px;
      margin-right: 4px;
    }
  }
  &__content {
    width: 100%;
  }
  &__count {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;
    margin-left: 4px;
  }
  &__title {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-default;
    align-items: center;
    width: 100%;
  }
  .icon-comment-empty {
    //font-size: 12px;
    margin-right: 4px;
  }
  &__icon-wrap {
    margin-left: 3px;
    display: flex;
    align-items: center;
  }

  &__message {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 4px;
  }
  &__new-message {
    box-sizing: content-box;
    position: absolute;
    width: 4px;
    height: 4px;
    background: $message-gradient;
    border-radius: 50%;
    right: 2px;
    top: -2px;
    transition: all 0.3s ease;

    border: 2px solid #fff;
  }
  &__icon {
    color: $icon-subdued;
    cursor: pointer;
  }
  &__time {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    color: $text-disabled;
    margin-left: auto;
  }
  &__message-text {
    padding: 0;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    color: $text-subdued;
    max-width: 80%;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
  }
}
</style>
